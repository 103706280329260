var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"card card-custom card-sticky"},[_c('div',{staticClass:"card-header"},[_c('div',{staticClass:"card-title"},[_c('span',{staticClass:"svg-icon svg-icon-lg svg-icon-3x svg-icon-primary mr-3"},[_c('inline-svg',{attrs:{"src":"media/svg/icons/Files/Compilation.svg"}})],1),_c('h3',{staticClass:"card-label"},[_vm._v(" "+_vm._s(_vm.getTitle)+" ")])]),_c('div',{staticClass:"card-toolbar"},[_c('button',{staticClass:"btn btn-light-dark font-weight-bolder mr-2",on:{"click":function($event){return _vm.$router.push({
            name: 'orders-view',
            params: {
              id: _vm.$route.params.id
            }
          })}}},[_c('i',{staticClass:"ki ki-long-arrow-back icon-lg"}),_vm._v(" Back ")]),_c('button',{staticClass:"btn btn-light-primary font-weight-bolder mr-2",on:{"click":function($event){return _vm.$router.push({
            name: 'order-deliverables-edit',
            params: {
              id: _vm.$route.params.id,
              deliverableId: _vm.$route.params.deliverableId
            }
          })}}},[_c('i',{staticClass:"fa fa-edit icon-lg"}),_vm._v(" Edit ")])])]),_c('div',{staticClass:"card-body pt-3"},[_c('div',{staticClass:"row justify-content-center"},[_c('div',{staticClass:"col-sm-12",domProps:{"innerHTML":_vm._s(_vm.deliverable.description)}}),(_vm.showFilesDatatable)?_c('div',{staticClass:"col-sm-12"},[_c('hr'),_c('h5',{staticClass:"text-center"},[_vm._v("Uploaded Files")]),_c('deliverable-files')],1):_vm._e()])])])}
var staticRenderFns = []

export { render, staticRenderFns }